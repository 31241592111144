<template>
  <div class="mt-32 p-4 mx-auto relative bg-gray-900">
    <div class="h-80 w-full absolute bottom-0 xl:inset-0 xl:h-full">
      <div class="h-full w-full xl:grid xl:grid-cols-2">
        <div class="h-full xl:relative xl:col-start-2">
          <img class="h-full w-full object-cover opacity-25 xl:absolute xl:inset-0"
               src="https://images.unsplash.com/photo-1521737852567-6949f3f9f2b5?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=2830&q=80&sat=-100"
               alt="People working on laptops"/>
          <div aria-hidden="true"
               class="absolute inset-x-0 top-0 h-32 bg-gradient-to-b from-gray-900 xl:inset-y-0 xl:left-0 xl:h-full xl:w-32 xl:bg-gradient-to-r"/>
        </div>
      </div>
    </div>
    <div
        class="max-w-4xl mx-auto px-4 sm:px-6 lg:max-w-7xl lg:px-8 xl:grid xl:grid-cols-2 xl:grid-flow-col-dense xl:gap-x-8">
      <div class="relative pt-12 pb-64 sm:pt-24 sm:pb-64 xl:col-start-1 xl:pb-24">
        <p class="mt-3 text-3xl font-extrabold text-white">Well done is better than well said</p>
        <p class="my-1 text-sm text-gray-300">~ Benjamin Franklin</p>
        <p class="mt-5 text-lg text-gray-300"><span class="text-red-600 font-bold">Founded in 2014, </span>we believe
          that the success of our products are a testament to the fact
          that we love what we do, and that we don't back off from a challenge. We've faced many challenges over the
          years but are humbled to say that hundreds of companies across South Africa trust our software to run
          essential parts of their businesses on it.</p>
        <div class="mt-12 grid grid-cols-1 gap-y-12 gap-x-6 sm:grid-cols-2">
          <p v-for="item in metrics" :key="item.id">
            <span class="block text-2xl font-bold text-white">{{ item.stat }}</span>
            <span class="mt-1 block text-base text-gray-300"
            ><span class="font-medium text-white">{{ item.emphasis }}</span> {{ item.rest }}</span
            >
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const metrics = [
  {id: 1, stat: '900+', emphasis: 'Companies', rest: 'trust us with their business'},
  {id: 2, stat: '800K+', emphasis: 'Assets', rest: 'managed on our platforms'},
  {id: 4, stat: '700K+', emphasis: 'Inspections Conducted', rest: 'using our apps'},
  {id: 5, stat: '750K+', emphasis: 'Tickets Managed', rest: 'using our portal'},
]

export default {
  name: 'AboutUsSection',
  setup() {
    return {
      metrics,
    }
  },
}
</script>
