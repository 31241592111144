<template>
  <div class="py-8 w-full">
    <!--Dotted Patterns-->
    <div class="px-4 sm:px-6 lg:px-8">
      <div class="text-lg max-w-prose mx-auto">
        <h1 class="mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
          We're Hiring!
        </h1>
         <p class="mt-8 text-xl text-gray-500 leading-8 text-center">We're looking for people with previous experience
          working with various web, mobile or testing technologies!<!--, or amazing young engineers just starting out to help craft our
          solutions.--></p>
        <div class="flex items-center justify-center my-8 w-full">
          <a href="https://sinov8.notion.site/Careers-Life-at-Sinov8-0180aad648c14a23b617ab449fe48476?pvs=4" target="_blank"
             class="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-red-800 hover:bg-red-700">
            Visit our careers page HERE</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ApplicationSteps from "./../components/ApplicationSteps.vue";
import FooterSection from "@/components/FooterSection";

export default {
  components: {ApplicationSteps, FooterSection}
};
</script>

<style scoped>
.prose-lg li {
  margin-top: 0.1em !important;
  margin-bottom: 0.1em !important;
}
</style>
