<template>
  <div class="w-full">
    <div class="p-6 mx-auto max-w-7xl sm:mt-12 lg:grid lg:grid-cols-12 lg:gap-8">
      <div class="sm:text-center md:max-w-2xl md:mx-auto lg:col-span-6 lg:text-left">
        <h1>
          <!--  <span class="block text-sm font-semibold uppercase tracking-wide text-gray-500
            sm:text-base lg:text-sm xl:text-base">SINOV8</span>-->
          <span class="mt-1 block text-4xl tracking-tight font-extrabold sm:text-5xl xl:text-6xl">
           <span class="block text-gray-900">Innovative Software</span>
           <span class="block text-indigo-600 text-4xl">is part of our DNA</span>
        </span>
        </h1>
        <p class="mt-3 text-base text-gray-500 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">
          There are few things in life we enjoy more than building software businesses. We're entrepreneurs at heart and
          technology opens the doors to any horizon we choose to look towards.
        </p>
        <div class="mt-8 bg-indigo-700 sm:rounded-lg shadow-md">
          <div class="px-4 py-5 sm:p-6">
            <h3 class="text-lg leading-6 font-medium text-indigo-100">
              We are Hiring!
            </h3>
            <div class="mt-2 max-w-xl text-sm text-indigo-100">
              <p>
                If you're a great full stack web developer looking for a new challenge or wanting to join an innovative
                team, give us a shout or use the link below to find our more about our career opportunities.
              </p>
            </div>
            <div class="mt-5">
              <a href="https://www.notion.so/sinov8/Careers-At-Sinov8-0180aad648c14a23b617ab449fe48476" target="_blank"
                 class="inline-flex items-center px-4 py-2 shadow-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm">
                Find out more &rarr;
              </a>
            </div>
          </div>
        </div>
      </div>
      <div
          class="mt-12 relative sm:max-w-lg sm:mx-auto lg:mt-0 lg:max-w-none lg:mx-0 lg:col-span-6 lg:flex lg:items-center">
        <svg
            class="absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-8 scale-75 origin-top sm:scale-100 lg:hidden"
            width="640" height="784" fill="none" viewBox="0 0 640 784" aria-hidden="true">
          <defs>
            <pattern id="4f4f415c-a0e9-44c2-9601-6ded5a34a13e" x="118" y="0" width="20" height="20"
                     patternUnits="userSpaceOnUse">
              <rect x="0" y="0" width="4" height="4" class="text-gray-200" fill="currentColor"/>
            </pattern>
          </defs>
          <rect y="72" width="640" height="640" class="text-gray-50" fill="currentColor"/>
          <rect x="118" width="404" height="784" fill="url(#4f4f415c-a0e9-44c2-9601-6ded5a34a13e)"/>
        </svg>
        <div class="relative mx-auto w-full rounded-lg shadow-lg lg:max-w-md">
          <div type="button"
               class="relative block w-full bg-white rounded-lg overflow-hidden focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
            <img class="w-full "
                 src="images/screenshot_rams_home_db.png"
                 alt=""/>
          </div>
        </div>
      </div>
    </div>
    <AboutUsSection></AboutUsSection>

    <!-- Logo cloud section -->
    <div class="mt-32">
      <div class="mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl">
        <div class="lg:grid lg:grid-cols-2 lg:gap-24 lg:items-center">
          <div>
            <h2 class="text-3xl font-extrabold text-gray-900 tracking-tight sm:text-4xl">
              Backed by rock solid, battle tested technologies
            </h2>
            <p class="mt-6 max-w-3xl text-lg leading-7 text-gray-500">
              We believe in using modern technologies that are both tried and tested, yet continue to push the boundaries of innovation.
              The advancements we’ve witnessed in our stacks (PHP/VueJS/Flutter/AWS etc.) over the last five years have been truly mind-blowing and constantly inspire us to build even better software.
            </p>
            <p class="mt-6 max-w-3xl text-lg leading-7 text-gray-500">
              We also believe in writing clean, testable & maintainable code. Everybody on our team loves to do things the right way
              to the best of their abilities. This is what creates the confidence to put our products out there and to
              build successful businesses with the right partners around these products.
            </p>
          </div>
          <div class="mt-12 grid grid-cols-2 gap-0.5 md:grid-cols-3 lg:mt-0 lg:grid-cols-2">
            <div v-for="logo in logos" :key="logo.name" class="col-span-1 flex justify-center py-8 px-8 bg-gray-50">
              <img class="max-h-12 filter grayscale opacity-30 hover:opacity-100 hover:grayscale-0" :src="logo.url"
                   :alt="logo.name"/>
            </div>
          </div>
        </div>
      </div>
    </div>
    <FooterSection/>
  </div>
</template>

<script>
const logos = [
  {name: 'Laravel', url: 'images/logo_laravel.png'},
  {name: 'Tailwindcss', url: 'images/logo_tailwindcss.png'},
  {name: 'VueJS', url: 'images/logo_vuejs.png'},
  {name: 'AWS', url: 'images/logo_aws.png'},
  // {name: 'Synthesis', url: 'images/logo_synthesis.png'},
  // {name: 'WeconnectU', url: 'images/logo_weconnectu.png'},
  {name: 'Basecamp', url: 'images/logo_basecamp.png'},
  {name: 'Github', url: 'images/logo_github.png'},
  {name: 'MySQL', url: 'images/logo_mysql.png'},
  {name: 'PHP Storm', url: 'images/logo_phpstorm.png'},
  {name: 'Docker', url: 'images/logo_docker.png'},
  {name: 'Flutter', url: 'images/logo_flutter.png'},
]

import {defineComponent} from 'vue'
import AboutUsSection from "../../src/components/AboutUsSection.vue";
import FooterSection from "@/components/FooterSection";

export default defineComponent({
  components: {FooterSection, AboutUsSection},
  setup() {
    return {
      logos
    }
  }
})
</script>
