import {createApp} from 'vue'
import './tailwind.css'
import App from './App.vue'
import {routes} from './routes.js'
import {createRouter, createWebHistory} from 'vue-router'
import TitleMixin from './title-mixin'

const app = createApp(App)

const router = createRouter({
    history: createWebHistory(),
    routes,
})
export default router

app.use(router)
app.mixin(TitleMixin)
app.mount('#app')
