<template>
  <header class="bg-white shadow w-full">
    <div class="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
      <h1 class="text-3xl font-bold leading-tight text-gray-900">Not Found</h1>
    </div>
    <FooterSection/>
  </header>
</template>
<script>
import FooterSection from "@/components/FooterSection";
export default {
  components: {FooterSection}
}
</script>