import Home from './views/Home.vue'
import Careers from './views/Careers.vue'
import NotFound from './views/NotFound.vue'
import OurSolutions from "./views/OurSolutions.vue";

/** @type {import('vue-router').RouterOptions['routes']} */
export const routes = [
    {
        path: '/',
        name: 'home',
        component: Home,
        meta: {title: 'Home'}
    },
    {
        path: '/careers',
        name: 'careers',
        component: Careers,
        meta: {title: 'Careers'}
    },
    {
        path: '/our-solutions',
        name: 'our-solutions',
        component: OurSolutions,
        meta: {title: 'Our Solutions'}
    },
    {
        path: '/:path(.*)',
        component: NotFound
    }
    ,
]
