<template>
  <div class="bg-gray-50 pt-16 overflow-hidden w-full">
    <div>
      <div class="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
        <div class="px-4 max-w-xl mx-auto sm:px-6 lg:py-16 lg:max-w-none lg:mx-0 lg:px-0">
          <div>

            <div class="mt-6">
              <h2 class="text-3xl font-extrabold tracking-tight text-gray-900">
                Rental Asset Management Solution
              </h2>
              <p class="mt-4 text-lg text-gray-500">
                Our Rental Asset Management Solutions, or Rams for short, is being rolled out under our partner brand WeconnectU
                and is quickly becoming the preferred software solution for residential property managers in South Africa.
                Launched in Sept 2019 and already trusted by more than 450 SME's all over the country.
              </p>
              <p class="mt-4 text-lg text-gray-500">
                Rams is an end to end Rental management solution that covers the full value chain of a rental process.
                Starting at the application process and offering modules for managing tenant screening, lease management, arrears
                management, account management, compliance management, reporting and more.
              </p>
              <div class="mt-6">
                <a href="https://weconnectu.co.za/rental-asset-management/" target="_blank"
                   class="inline-flex px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-gray-800 hover:bg-gray-600">
                  Go to our dedicated product page to find out more ->
                </a>
              </div>
            </div>
          </div>
          <div class="mt-8 border-t border-gray-200 pt-6">
            <blockquote>
              <div>
                <p class="text-base text-gray-500">
                  &ldquo;WeconnectU covers everything. It's the best Rental Management and Inspection system out there. We
                  have searched globally and this out-classes all the rivals. Extremely user-friendly, uncluttered, and
                  effective. We just love the full bank integration in WeconnectU and the fact that we retain our own
                  Trust account means transparency and security through proper audit. Brilliant support, always prepared
                  to consider requests and opinions. Frequent new releases and updates never fails to impress! Thank you
                  to the most amazing, ethical and professional WeconnectU team.&rdquo;
                </p>
              </div>
              <footer class="mt-3">
                <div class="flex items-center space-x-3">
                  <div class="flex-shrink-0">
                    <!-- Add Photo Here -->
                  </div>
                  <div class="text-base font-medium text-gray-700">
                    Charl Kuffner, Harcourts Dunn General Manager
                  </div>
                </div>
              </footer>
            </blockquote>
          </div>
        </div>
        <div class="mt-12 sm:mt-16 lg:mt-0">
          <div class="pl-4 -mr-48 sm:pl-6 md:-mr-16 lg:px-0 lg:m-0 lg:relative lg:h-full">
            <img
                class="w-full rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:left-0 lg:h-full lg:w-auto lg:max-w-none"
                src="images/rams-home-db-2.png"
                alt="Inbox user interface">
          </div>
        </div>
      </div>
    </div>
    <div class="mt-24">
      <div class="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
        <div class="px-4 max-w-xl mx-auto sm:px-6 lg:py-32 lg:max-w-none lg:mx-0 lg:px-0 lg:col-start-2">
          <div>
            <div class="mt-6">
              <h2 class="text-3xl font-extrabold tracking-tight text-gray-900">
                RedRabbit Inspections & Maintenance Management
              </h2>
              <p class="mt-4 text-lg text-gray-500">
                RedRabbit has already been the leading software solution for Inspections & Maintenance management in South Africa for a few years
                now with endorsements from all the big players in the Real Estate industry including Just Property, Only Realty, Rawson, Chas Everitt, harcourts
                and many others. We are closing in on 900 companies all over South Africa using the software on a daily basis.
              </p>
              <p class="mt-4 text-lg text-gray-500">
                RedRabbit offers a very comprehensive feature set specifically aimed at conducting property inspections and then
                effectively reporting on them. It also seamlessly integrates with a state of the art maintenance ticketing system that
                allows users to manager maintenance tickets as projects whilst keeping their customers in the loop with email & sms communication
                every step of the way.
              </p>
              <div class="mt-6">
                <a href="https://redrabbit.zone/" target="_blank"
                   class="inline-flex px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-red-800 hover:bg-red-700">
                  Click here to find out more ->
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="mt-12 sm:mt-16 lg:mt-0 lg:col-start-1">
          <div class="pr-4 -ml-48 sm:pr-6 md:-ml-16 lg:px-0 lg:m-0 lg:relative lg:h-full">
            <img
                class="w-full rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:right-0 lg:h-full lg:w-auto lg:max-w-none"
                src="images/redrabbit_home_dashboard_2023.png"
                alt="Customer profile user interface">
          </div>
        </div>
      </div>
    </div>
    <FooterSection/>
  </div>
</template>

<script>
import {defineComponent} from 'vue'
import FooterSection from "@/components/FooterSection";

export default defineComponent({
  name: 'Our Solutions',
  components: {FooterSection},
})
</script>
